import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/cotizacion',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'cotizacion',
        loadChildren: () => import('./cotizador/form-cotizacion/form-cotizacion.module').then(m => m.FormCotizacionModule),

    },
    {
        path: 'cotizaciones/:idProcedure/:idProducto/:idCategoria/:idSubMarca',
        loadChildren: () => import('./cotizador/cotizaciones/cotizaciones.module').then(m => m.CotizacionesModule),
    },
    {
        path:  'pago/:idProcedure/:idAseguradora/:idCotizacion/:idTypeUser',
        loadChildren: () => import('./cotizador/form-pago/form-pago.module').then(m => m.FormPagoModule),
    },
    {
        path: 'poliza/:idProcedure/:idAseguradora/:idCotizacion',
        loadChildren: () => import('./cotizador/emision-poliza/emision-poliza.module').
        then(m => m.EmisionPolizaModule),
    },
    {
        path: 'pdf-poliza/:idProcedure/:idAseguradora/:idCotizacion/:idPoliza',
        loadChildren: () => import('./cotizador/pdf-poliza/pdf-poliza.module').
        then(m => m.PdfPolizaModule),
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
