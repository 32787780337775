import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { SharedRoutingModule } from './shared-routing.module';
import { FooterComponent } from './footer/footer.component';
import { BannerCotizaComponent } from './banners/banner-cotiza/banner-cotiza.component';
import { BannerPolizaComponent } from './banners/banner-poliza/banner-poliza.component';
import { BannerPagoComponent } from './banners/banner-pago/banner-pago.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { LoaderBanorteComponent } from './loader-contents/loader-banorte/loader-banorte.component';
import { LoaderAfirmeComponent } from './loader-contents/loader-afirme/loader-afirme.component';
import { LoaderAnaComponent } from './loader-contents/loader-ana/loader-ana.component';
import { LoaderGnpComponent } from './loader-contents/loader-gnp/loader-gnp.component';
import { LoaderQualitasComponent } from './loader-contents/loader-qualitas/loader-qualitas.component';
import { LoaderGeneralSegurosComponent } from './loader-contents/loader-general-seguros/loader-general-seguros.component';
import { LoaderWaitComponent } from './loader-wait/loader-wait.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalCompraComponent } from '../cotizador/modal-compra/modal-compra.component';
import { ModalRechazoComponent } from '../cotizador/modal-rechazo/modal-rechazo.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        BannerCotizaComponent,
        BannerPolizaComponent,
        BannerPagoComponent,
        PreloaderComponent,
        LoaderBanorteComponent,
        LoaderAfirmeComponent,
        LoaderAnaComponent,
        LoaderGnpComponent,
        LoaderQualitasComponent,
        LoaderGeneralSegurosComponent,
        LoaderWaitComponent,
        ModalCompraComponent,
        ModalRechazoComponent,
        WhatsappComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedRoutingModule,
        NgxSpinnerModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        BannerCotizaComponent,
        BannerPolizaComponent,
        BannerPagoComponent,
        PreloaderComponent,
        LoaderBanorteComponent,
        LoaderAfirmeComponent,
        LoaderAnaComponent,
        LoaderGnpComponent,
        LoaderQualitasComponent,
        LoaderGeneralSegurosComponent,
        LoaderWaitComponent,
        NgxSpinnerModule,
        WhatsappComponent
    ]
})
export class SharedModule { }
