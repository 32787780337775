import { Observable } from 'rxjs';
import { ProcedureRequestDTO, ProcedureResponseDTO, FetchProcedureDTO } from '../../data/dto/ProcedureDTO';
import { UpdateRequestDTO, UpdateRequestResponseDTO } from '../../data/dto/ProcedureDTO';
import { ProcedureDTO, GetProcedureDTO } from '../../data/dto/GetProcedureDTO';
import { EstadoDTO, TypeDocumentDTO, CivilStateDTO, LocalizationRequestDTO } from '../../data/dto/ContractorDTO';
import { ContractorDTO, ContractorRequestDTO, AddressRequestDTO, AddressDTO, LocalizationResposeDTO} from '../../data/dto/ContractorDTO';
import { DriverRequestDTO, DriverDTO, ContractorFetchDTO, ContractorFetchRequestDTO } from '../../data/dto/ContractorDTO';
import { CityDTO, CityRequestDTO } from '../../data/dto/ContractorDTO';
import { ContractorUpdateDTO, AddressUpdateDTO, DriverUpdateDTO } from '../../data/dto/ContractorDTO';

export abstract class ProcedureRepository {
    abstract createProcedure(procedure: ProcedureRequestDTO): Observable<ProcedureResponseDTO>;
    abstract updateProcedureVehicle(procedure: UpdateRequestDTO): Observable<UpdateRequestResponseDTO>;
    abstract getProcedure(procedure: GetProcedureDTO): Observable<ProcedureDTO>;
    abstract getStateList(): Observable<EstadoDTO>;
    abstract getTypeDocumentList(): Observable<TypeDocumentDTO>;
    abstract getCivilStateList(): Observable<CivilStateDTO>;
    abstract createContractor(request: ContractorRequestDTO): Observable<ContractorDTO>;
    abstract createAddressContractor(address: AddressRequestDTO): Observable<AddressDTO>;
    abstract createDriverContractor(driver: DriverRequestDTO): Observable<DriverDTO>;
    abstract fetchContractor(contractor: ContractorFetchRequestDTO): Observable<ContractorFetchDTO>;
    abstract getCitiesList(city: CityRequestDTO): Observable<CityDTO>;
    abstract updateContractor(request: ContractorUpdateDTO): Observable<ContractorDTO>;
    abstract updateAddressContractor(address: AddressUpdateDTO): Observable<AddressDTO>;
    abstract updateDriverContractor(driver: DriverUpdateDTO): Observable<DriverDTO>;
    abstract fetchProcedure(procedure: FetchProcedureDTO): Observable<ProcedureResponseDTO>;
    abstract getLocalization(cp: LocalizationRequestDTO): Observable<LocalizationResposeDTO>;
}
