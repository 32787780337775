import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpanishLocalization, Localization } from './shared/localization';
import { NameBrands, Brands } from './shared/constants';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { BootstrapModule } from './bootstrap/bootstrap.module';
import { AuthRestRepository } from './data/repository/AuthRestRepository';
import { AuthRepository } from './core/repositories/AuthRepository';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { VehicleRepository } from './core/repositories/VehicleRepository';
import { VehicleRestRepository } from './data/repository/VehicleRestRepository';
import { ProcedureRepository } from './core/repositories/ProcedureRepository';
import { ProcedureRestRepository } from './data/repository/ProcedureRestRepository';
import { QuotationRepository } from './core/repositories/QuotationRepository';
import { QuotationRestRepository } from './data/repository/QuotationRestRepository';
import { PolizaRepository } from './core/repositories/PolizaRepository';
import { PolizaRestRepository } from './data/repository/PolizaRestRepository';
import { DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpCancelService } from './http-cancel/service/http-cancel-services.service';
import { ManageHttpInterceptor } from './http-cancel/interceptor/managehttp.interceptor';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    MatDialogModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    BootstrapModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgSelectModule
  ],
  providers: [
    Title,
    { provide: Localization, useClass: SpanishLocalization },
    { provide: Brands, useClass: NameBrands },
    { provide: AuthRepository, useClass: AuthRestRepository},
    { provide: VehicleRepository, useClass: VehicleRestRepository},
    { provide: ProcedureRepository, useClass: ProcedureRestRepository},
    { provide: QuotationRepository, useClass: QuotationRestRepository },
    { provide: PolizaRepository, useClass: PolizaRestRepository },
    DatePipe,
    HttpCancelService,
    {provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
