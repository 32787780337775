import { ProcedureRepository } from 'src/app/core/repositories/ProcedureRepository';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProcedureRequestDTO, ProcedureResponseDTO, FetchProcedureDTO } from 'src/app/data/dto/ProcedureDTO';
import { UpdateRequestDTO, UpdateRequestResponseDTO } from 'src/app/data/dto/ProcedureDTO';
import { GetProcedureDTO, ProcedureDTO } from 'src/app/data/dto/GetProcedureDTO';
import { EstadoDTO, TypeDocumentDTO, CivilStateDTO, DriverUpdateDTO, AddressUpdateDTO, ContractorUpdateDTO, LocalizationRequestDTO } from 'src/app/data/dto/ContractorDTO';
import { ContractorDTO, ContractorRequestDTO, AddressRequestDTO, AddressDTO } from 'src/app/data/dto/ContractorDTO';
import { DriverDTO, DriverRequestDTO, ContractorFetchDTO, ContractorFetchRequestDTO, LocalizationResposeDTO } from 'src/app/data/dto/ContractorDTO';
import { CityDTO, CityRequestDTO } from 'src/app/data/dto/ContractorDTO';

@Injectable({
    providedIn: 'root'
})
export class ProcedureRestRepository implements ProcedureRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    createProcedure(procedure: ProcedureRequestDTO): Observable<ProcedureResponseDTO> {
        const endpoint = environment.procedure_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            post<ProcedureResponseDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(procedure), httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    fetchProcedure(procedure: FetchProcedureDTO): Observable<ProcedureResponseDTO> {
        const endpoint = environment.procedure_endpoint;
        const id = procedure.idProcedure;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<ProcedureResponseDTO>(`${environment.url_base}/${endpoint}/${id}`,  httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    updateProcedureVehicle(procedure: UpdateRequestDTO): Observable<UpdateRequestResponseDTO> {
        const endpoint = environment.procedure_endpoint;
        const idProcedure = procedure.id;
        const httpOptions = { headers: this.headers };
        return this.http.
            put<UpdateRequestResponseDTO>(`${environment.url_base}/${endpoint}/${idProcedure}`, JSON.stringify(procedure), httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getProcedure(procedure: GetProcedureDTO): Observable<ProcedureDTO> {
        const endpoint = environment.procedure_endpoint;
        const idProcedure = procedure.idProcedure;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<ProcedureDTO>(`${environment.url_base}/${endpoint}/${idProcedure}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getStateList(): Observable<EstadoDTO> {
        const endpoint = environment.list_estado_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<EstadoDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getTypeDocumentList(): Observable<TypeDocumentDTO> {
        const endpoint = environment.list_type_document_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<TypeDocumentDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getCivilStateList(): Observable<CivilStateDTO> {
        const endpoint = environment.list_civil_state_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<CivilStateDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getLocalization(cp: LocalizationRequestDTO): Observable<LocalizationResposeDTO>{
      const endpoint = environment.localization_endpoint;
      const codigoP = cp.codigoPostal;
      const httpOptions = { headers: this.headers };
      return this.http.
      get<LocalizationResposeDTO>(`${environment.url_base}/${endpoint}/${codigoP}`, httpOptions)
      .pipe(
        retry(2),
        catchError(this.handleError)
      )
      .pipe(map(result => {
        return result;
      }));
    }
    createContractor(request: ContractorRequestDTO): Observable<ContractorDTO> {
        const endpoint = environment.contractor_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            post<ContractorDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    updateContractor(request: ContractorUpdateDTO): Observable<ContractorDTO> {
        const endpoint = environment.contractor_endpoint;
        const idContractor = request.id;
        const httpOptions = { headers: this.headers };
        return this.http.
            put<ContractorDTO>(`${environment.url_base}/${endpoint}/${idContractor}`, JSON.stringify(request), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    createAddressContractor(request: AddressRequestDTO): Observable<AddressDTO> {
        const endpoint = environment.address_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            post<AddressDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(request), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    updateAddressContractor(request: AddressUpdateDTO): Observable<AddressDTO> {
        const endpoint = environment.address_endpoint;
        const idAddress = request.id;
        const httpOptions = { headers: this.headers };
        return this.http.
            put<AddressDTO>(`${environment.url_base}/${endpoint}/${idAddress}`, JSON.stringify(request), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    createDriverContractor(driver: DriverRequestDTO): Observable<DriverDTO> {
        const endpoint = environment.driver_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            post<DriverDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(driver), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    updateDriverContractor(driver: DriverUpdateDTO): Observable<DriverDTO> {
        const endpoint = environment.driver_endpoint;
        const idDriver = driver.id;
        const httpOptions = { headers: this.headers };
        return this.http.
            put<DriverDTO>(`${environment.url_base}/${endpoint}/${idDriver}`, JSON.stringify(driver), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    fetchContractor(contractor: ContractorFetchRequestDTO ): Observable<ContractorFetchDTO> {
        const endpoint = environment.contractor_endpoint;
        const idContratante = contractor.idContractor;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<ContractorFetchDTO>(`${environment.url_base}/${endpoint}/${idContratante}`, httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getCitiesList(city: CityRequestDTO): Observable<CityDTO> {
        const endpoint = environment.list_cities_endpoint;
        const idEstado = city.idEstado;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<CityDTO>(`${environment.url_base}/${endpoint}/${idEstado}/ciudad`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        return throwError(error);
    }
}
