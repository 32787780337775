import { VehicleRepository } from 'src/app/core/repositories/VehicleRepository';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ServiceListDTO, TypeUseDTO, NationalityListDTO, VehicleRequestDTO, VehicleDTO } from 'src/app/data/dto/VehicleDTO';
import { BrandListDTO, BrandListResponseDTO } from 'src/app/data/dto/VehicleDTO';
import { ModelListDTO, GenderListDTO, TypeUserListDTO } from 'src/app/data/dto/VehicleDTO';
import { SubrandRequestDTO, SubrandListDTO } from 'src/app/data/dto/VehicleDTO';
import { DescriptionListDTO, DescriptionRequestDTO } from 'src/app/data/dto/VehicleDTO';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VehicleRestRepository implements VehicleRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    getTypeUse(): Observable<TypeUseDTO> {
        const endpoint = environment.type_use_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<TypeUseDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getBrandList(brand: BrandListDTO): Observable<BrandListResponseDTO> {
        const endpoint = environment.brand_list_endpoint;
        const idCompany = environment.companyID;
        const idProducto = brand.productoId;
        const idCategoria = brand.categoriaId;
        const idModelo = environment.modeloId;
        const httpOptions = { headers: this.headers };
        return this.http
        .get<BrandListResponseDTO>(`${environment.url_base}/${endpoint}/${idCompany}/${idProducto}/${idCategoria}/${idModelo}`, httpOptions)
        .pipe(
            retry(1),
            catchError(this.handleError)
        )
        .pipe(map(result => {
            return result;
        }));
    }

    getModel(): Observable<ModelListDTO> {
        const endpoint = environment.list_model_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<ModelListDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getSubrand(subrand: SubrandRequestDTO): Observable<SubrandListDTO> {
        const endpoint = environment.list_subrand_endpoint;
        const idCompany = environment.companyID;
        const idProducto = subrand.productoId;
        const idCategoria = subrand.categoriaId;
        const idModelo = subrand.modeloId;
        const idMarca = subrand.marcaId;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<SubrandListDTO>(`${environment.url_base}/${endpoint}/${idCompany}/${idProducto}/${idCategoria}/${idMarca}/${idModelo}`,
            httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getDescription(description: DescriptionRequestDTO): Observable<DescriptionListDTO> {
        const endpoint = environment.list_description_endpoint;
        const idCompany = environment.companyID;
        const idProducto = description.productoId;
        const idCategoria = description.categoriaId;
        const idModelo = description.modeloId;
        const idMarca = description.marcaId;
        const idSubmarca = description.submarcaId;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<DescriptionListDTO>(`${environment.url_base}/${endpoint}/${idCompany}/${idProducto}/${idCategoria}/${idMarca}/${idSubmarca}/${idModelo}`,
            httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getGender(): Observable<GenderListDTO> {
        const endpoint = environment.type_gender;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<GenderListDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getTypeUser(): Observable<TypeUserListDTO> {
        const endpoint = environment.type_user;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<TypeUserListDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getService(): Observable<ServiceListDTO[]> {
        const endpoint = environment.type_service;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<ServiceListDTO[]>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    getNationality(): Observable<NationalityListDTO> {
        const endpoint = environment.list_nationality;
        const httpOptions = { headers: this.headers };
        return this.http.
            get<NationalityListDTO>(`${environment.url_base}/${endpoint}`, httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    createVehicle(driver: VehicleRequestDTO): Observable<VehicleDTO> {
        const endpoint = environment.vehicle_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            post<VehicleDTO>(`${environment.url_base}/${endpoint}`, JSON.stringify(driver), httpOptions)
            .pipe(
                retry(1),
                catchError(this.handleError)
            )
            .pipe(map(result => {
                return result;
            }));
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        return throwError(error);
    }

}
