import { Component } from '@angular/core';
import { environment, envtemplate } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public envtemplate = envtemplate;
  title = 'gs-nayeeri-multicotizador-frontend';
}
