<div class="container-fluid banner-top banner-img" >
    <div class="container">
        <div class="row">
            <div class="col-8 col-md-6">
                <h1 class="border-left-verde">{{ localization.titlePago }}</h1>
            </div>
        </div>
    </div>
</div>

