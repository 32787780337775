import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';

@Component({
  selector: 'app-banner-cotiza',
  templateUrl: './banner-cotiza.component.html',
  styleUrls: ['./banner-cotiza.component.scss']
})
export class BannerCotizaComponent implements OnInit {
  localization: Localization;

  constructor(localization: Localization) {
    this.localization = localization;
  }

  ngOnInit(): void {
  }

}
