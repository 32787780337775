import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbProgressbarModule, NgbToastModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [

    ],
    imports: [
        CommonModule,
        NgbProgressbarModule,
        NgbToastModule,
        NgbModalModule,
        NgbModule
    ],
    exports: [
        NgbProgressbarModule,
        NgbToastModule,
        NgbModalModule,
        NgbModule
    ]
})
export class BootstrapModule { }
