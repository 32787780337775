<div   mat-dialog-title>
    <!--<button (click)="onNoClick()"  mat-button mat-dialog-close type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>-->
    <h1 style="color:#88c249;">x </h1>
</div>

<div mat-dialog-content  >
    <div class="modal-body text-center">
        <h1  class="color-texto" style="font-size: 1.75rem;font-weight: bold;margin-bottom: .5rem;text-align: center !important;">Transacción  Exitosa</h1>
        <p class="color-texto"> Se autorizó por un monto de <br> {{data.monto}} MXN</p>
        <p class="color-texto" style="font-size: 1.75rem;font-weight: bold;margin-bottom: .5rem;text-align: center !important;">No. de Transacción: | {{ data.noTransaccion}}</p>
          <i class="color-texto" style="font-size: 90px;" class="fas fa-check-circle"></i>
        <h1 class="color-texto" style="font-size: 1.75rem;font-weight: bold;margin-bottom: .5rem;text-align: center !important;">¡Felicidades!</h1>
        <br>
       <p class="color-texto">Gracias por usar nuestro servicio en línea.</p>
    </div>
</div>

<div mat-dialog-actions>
    <a  type="button" (click)="onNoClick()" class="btn btn-return  btn-block btn-sm">Aceptar</a>
</div>

