import { Observable } from 'rxjs';
import { PolizaDTO, GetPolizaDTO, PDFPolizaDTO} from '../../data/dto/PolizaDTO';
import { PDFCotizacionDTO, GetCotizacionDTO, SendPolizaDTO, SendPolizaRequestDTO } from '../../data/dto/PolizaDTO';

export abstract class PolizaRepository {
    abstract createPoliza(poliza: GetPolizaDTO): Observable<PolizaDTO>;
    abstract getPolizaPDF(file: GetPolizaDTO): Observable<PDFPolizaDTO>;
    abstract getCotizacionPDF(file: GetCotizacionDTO): Observable<PDFCotizacionDTO>;
    abstract getCotizacionPDFAgentes(file: GetCotizacionDTO): Observable<PDFCotizacionDTO>;
    abstract printCotizacionPDF(file: GetCotizacionDTO): Observable<PDFCotizacionDTO>;
    abstract sendPolizaPDF(file: SendPolizaDTO): Observable<SendPolizaRequestDTO>;
}
