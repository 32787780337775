import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-ana',
  templateUrl: './loader-ana.component.html',
  styleUrls: ['./loader-ana.component.scss']
})
export class LoaderAnaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
