<div   mat-dialog-title>
    <!--<button mat-button mat-dialog-close (click)="onNoClick()"   type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h1 style="color:#88c249;">x </h1>-->
</div>

<div mat-dialog-content  >
    <div class="modal-body text-center">
        <h1  class="color-texto" style="font-size: 1.75rem;font-weight: bold;margin-bottom: .5rem;text-align: center !important;">Su Transacción  <br> ha sido rechazada</h1>
        <br>
        <p class="color-texto">Favor de validar con su banco o intentar realizar <br> el cargo con otra tarjeta</p>
    </div>
</div>

<div mat-dialog-actions>
    <a  type="button" (click)="onNoClick()" class="btn btn-return  btn-block btn-sm">Aceptar</a>
</div> 