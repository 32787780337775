import { QuotationRepository } from 'src/app/core/repositories/QuotationRepository';
import { Observable, throwError } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  QuotationDTO,
  QuotationRequestDTO,
  QuotationValidateDTO,
} from 'src/app/data/dto/QuotationDTO';

@Injectable({
  providedIn: 'root',
})
export class QuotationRestRepository implements QuotationRepository {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }


  public getQuotation(request: QuotationRequestDTO): Observable<QuotationDTO> {
    const endpoint = environment.cotizaciones_endpoint;
    const idCompany = request.companyId;
    const idProducto = request.productoId;
    const idCategory = request.categoriaId;
    const idMarca = request.marcaId;
    const idsubMarca = request.submarca;
    const idModelo = request.modeloId;
    const keyAseg = request.claveAseguradora;
    const cp = request.cp;
    const featureId = request.caracteristicaId;
    const tramiteId = request.tramiteId;
    const tipoPersonaId = request.tipoPersonaId;
    const sexoId = request.sexoId;
    const payForm = request.tipoPago;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<QuotationDTO>(
        `${environment.url_base}/${endpoint}/${idCompany}/${idProducto}/${idCategory}/${idMarca}/${idsubMarca}/${idModelo}/${keyAseg}/${cp}/${featureId}/${tramiteId}/${tipoPersonaId}/${sexoId}/${payForm}`,
        httpOptions
      ).pipe(
        map((e) => {
          if (e.errorCodigo === '0') {
            return null;
          }
          return e;
        })
      );
  }

  validateQuotation(request: QuotationValidateDTO): Observable<QuotationDTO> {
    const endpoint = environment.cotizacion_validate_endpoint;
    const idProcedure = request.tramiteId;
    const idCompany = request.companyId;
    const httpOptions = { headers: this.headers };
    return this.http
      .get<QuotationDTO>(
        `${environment.url_base}/${endpoint}/${idProcedure}/${idCompany}`,
        httpOptions
      )
      .pipe(catchError(this.handleError))
      .pipe(
        map((result) => {
          return result;
        })
      );
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(error);
  }
}
