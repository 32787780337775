import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-qualitas',
  templateUrl: './loader-qualitas.component.html',
  styleUrls: ['./loader-qualitas.component.scss']
})
export class LoaderQualitasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
