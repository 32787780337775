<!-- header id="header">
  <div class="container align-items-center">
    <div class="logo mr-auto col-9">
      <a href="#">
        <img src="assets/img/logo.png" alt="Logotipo de ISIIX Inteligencia en seguros institucionales e individuales" class="img-fluid">
      </a>
    </div>
    <div *ngIf="numAgente !== null && numAgente !== '' ">
      <input value="Agente: {{numAgente}}" class="input-config" disabled>
    </div>
  </div>
</header -->


<header class="mb-4 bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center mt-2 mb-0 pt-0">
        <a class="navbar-brand" href="https://isiix.com.mx">
          <img src="{{ envtemplate.principallogo }}" class="img-fluid" width="144" height="38" alt="Logotipo de ISIIX Inteligencia en seguros institucionales e individuales">
        </a>
      </div>
    </div>
  </div>
  <hr>
</header>
