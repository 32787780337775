<footer id="footer" role="contentinfo" class="footer pb-0" [ngStyle]="{'background-color': envtemplate.footerbackgroundcolor}" >
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-6">
				<img src="{{ envtemplate.principallogo }}" width="{{envtemplate.footerlogowidth}}" alt="logotipo isiix">
				<p class="mt-4 pt-2" style="text-align: justify; line-height:15px; font-size:13px;"><b style="color: #060443;">ISIIX, INTELIGENCIA EN SEGUROS</b> estamos comprometidos en protegerte a ti, a tus seres queridos y tus bienes. Como parte de esta protección, te informamos que los datos personales que nos proporciones o se obtengan, son tratados con absoluta confidencialidad y que contamos con las medidas de seguridad suficientes para garantizar su protección en apego a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
			</div>
			<div class="col-12 col-lg-6 mt-5 pt-5 text-right">
				<ul class="list-inline mt-3 pt-3 text-dark">
					<li class="list-inline-item mr-3"><a href="" class="text-dark">Términos y condiciones</a></li>
					<li class="list-inline-item mr-3"><a href="" class="text-dark">Aviso de privacidad</a></li>
					<li class="list-inline-item"><a href="" class="text-dark">Contacto</a></li>
				</ul>

			</div>
		</div>
	</div>
	<div class="container-fluid mt-3" [ngStyle]="{'background-color': envtemplate.footerbackgroundcolor}">
		<div class="container">


		<div class="row">
			<div class="col-12 col-lg-12 pt-4 pb-3" >
				<div id="copyright" style="font-family:'Montserrat', sans-serif; font-size: 15px; font-weight: 400;">
					© 2021 Copyright | ISIIX  INTELIGENCIA EN SEGUROS INSTITUCIONALES E INDIVIDUALES
				</div>
			</div>
</div>
		</div>

	</div>
</footer>
