import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';

@Component({
  selector: 'app-banner-poliza',
  templateUrl: './banner-poliza.component.html',
  styleUrls: ['./banner-poliza.component.scss']
})
export class BannerPolizaComponent implements OnInit {
  localization: Localization;

  constructor(localization: Localization) {
    this.localization = localization;
  }

  ngOnInit(): void {
  }

}
