export abstract class Brands {
    ACURA: string;
    AUDI: string;
    BMW: string;
    MINI: string;
    JEEP: string;
    MAZDA: string;
    MERCEDES_BENZ: string;
    NISSAN: string;
    KIA: string;
    VOLKSWAGEN: string;
}

export class NameBrands implements Brands {
    ACURA = 'ACURA';
    AUDI = 'AUDI';
    BMW = 'BMW';
    MINI = 'MINI';
    JEEP = 'JEEP';
    MAZDA = 'MAZDA';
    MERCEDES_BENZ = 'MBENZ';
    NISSAN = 'NISSAN';
    KIA = 'KIA';
    VOLKSWAGEN = 'VOLKSWAGEN';
}
