import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  localization: Localization;
  constructor( localization: Localization ) {
    this.localization = localization;
  }

  ngOnInit(): void {
  }

}
