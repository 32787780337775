import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { AuthRepository } from '../../core/repositories/AuthRepository';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthModel, AuthResponseModel } from '../../core/model/AuthModel';
import { AuthDTO } from './../dto/AuthDTO';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
    providedIn: 'root'
})

export class AuthRestRepository extends AuthRepository {
    private headers: HttpHeaders;

    constructor(private http: HttpClient,  private auth: AuthService) {
        super();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }

    login(auth: AuthModel): Observable<AuthResponseModel> {
        const endpoint = environment.auth_endpoint;
        const httpOptions = { headers: this.headers };
        return this.http.
            post<AuthResponseModel>(`${environment.url_base}/${endpoint}`, JSON.stringify(auth), httpOptions)
            .pipe(
                retry(2),
                catchError(this.handleError)
            )
            .pipe(map(authResponse => {
                return this.auth.login(authResponse.token);
            }));
    }

    handleError(error: HttpErrorResponse): Observable<never> {
        return throwError(error);
    }
}
