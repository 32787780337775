import { Component, DoCheck, OnInit } from '@angular/core';
import { Localization } from 'src/app/shared/localization';
import { AuthService } from 'src/app/auth/service/auth.service';
import { environment, envtemplate } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, DoCheck {
  localization: Localization;
  numAgente: string;
  public envtemplate = envtemplate;

  constructor( localization: Localization, private service: AuthService ) {
    this.localization = localization;
  }

  ngDoCheck(): void {
    this.numAgente = localStorage.getItem('claveAgente');
  }

  ngOnInit(): void {
    setTimeout(function(){
      window.tidioChatApi.setColorPalette(envtemplate.tidioChatColor);
    },1000);
    
  }

}
