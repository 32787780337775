<div class="loader-poliza">
    <div class="ctn-sparkles">
        <svg width="200px" height="200px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g id="sparkles">
                <path d="m55.301 8.3008h0.19922c0.5 0 0.89844-0.30078 1-0.80078l0.60156-3.3984c0.10156-0.5-0.30078-1.1016-0.80078-1.1992-0.5-0.10156-1.1016 0.30078-1.1992 0.80078l-0.60156 3.3984c-0.10156 0.59766 0.30078 1.0977 0.80078 1.1992z"></path>    
                <path d="m78.5 10.5c-0.39844-0.39844-1-0.39844-1.3984 0l-0.30078 0.30078-0.30078-0.30078c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.30078 0.30078-0.30078 0.30078c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078l0.30078-0.30078 0.30078 0.30078c0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078c0.39844-0.39844 0.39844-1 0-1.3984l-0.30078-0.30078 0.30078-0.30078c0.39844-0.39844 0.39844-1 0-1.3984z"></path>
                <path d="m76.602 26.699l-1.1992 1.1992c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078l1.1992-1.1992c0.39844-0.39844 0.39844-1 0-1.3984-0.39844-0.39844-1-0.39844-1.3984 0z"></path>
                <path d="m89.5 22.5c0 1.6016-2.3984 1.6016-2.3984 0s2.3984-1.6016 2.3984 0"></path>
                <path d="m95.898 37.801l0.5-0.5c0.39844-0.39844 0.39844-1 0-1.3984-0.39844-0.39844-1-0.39844-1.3984 0l-0.5 0.5-0.5-0.50391c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.5 0.5-0.5 0.5c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078l0.5-0.5 0.5 0.5c0.19922 0.19922 0.5 0.30078 0.69922 0.30078s0.5-0.10156 0.69922-0.30078c0.39844-0.39844 0.39844-1 0-1.3984z"></path>
                <path d="m82.082 40.375c1.3203 0.90234-0.03125 2.8867-1.3516 1.9844-1.3242-0.89844 0.027343-2.8828 1.3516-1.9844"></path>
                <path d="m74.957 49.684c0.80078 1.3828-1.2773 2.5859-2.0781 1.1992-0.80078-1.3867 1.2773-2.5859 2.0781-1.1992"></path>
                <path d="m86.199 56.898c0.5 0 0.89844-0.30078 1-0.80078 0.10156-0.5-0.30078-1.1016-0.80078-1.1992l-3.3984-0.60156c-0.5-0.10156-1.1016 0.30078-1.1992 0.80078-0.10156 0.5 0.30078 1.1016 0.80078 1.1992l3.3984 0.60156h0.19922z"></path>
                <path d="m92.465 65c1.5898 0.17578 1.3281 2.5586-0.26562 2.3828-1.5898-0.17578-1.3242-2.5586 0.26562-2.3828"></path>
                <path d="m77 69.398h-0.69922v-0.69922c0-0.60156-0.39844-1-1-1-0.60156 0-1 0.39844-1 1v0.69922h-0.69922c-0.60156 0-1 0.39844-1 1 0 0.60156 0.39844 1 1 1h0.69922v0.60156c0 0.60156 0.39844 1 1 1 0.60156 0 1-0.39844 1-1v-0.69922h0.69922c0.60156 0 1-0.39844 1-1 0-0.60156-0.5-0.90234-1-0.90234z"></path>
                <path d="m85.602 79.301c-1.3984 0-2.5 1.1016-2.5 2.5 0 1.3984 1.1016 2.5 2.5 2.5 1.3984 0 2.5-1.1016 2.5-2.5 0-1.3008-1.1016-2.5-2.5-2.5zm0 3c-0.30078 0-0.5-0.19922-0.5-0.5s0.19922-0.5 0.5-0.5c0.30078 0 0.5 0.19922 0.5 0.5s-0.20312 0.5-0.5 0.5z"></path>
                <path d="m73.352 86.312c1.5938 0.17578 1.3281 2.5586-0.26172 2.3828-1.5898-0.17578-1.3281-2.5586 0.26172-2.3828"></path>
                <path d="m59 88.699c-1.8008 0-3.3008 1.5-3.3008 3.3008s1.5 3.3008 3.3008 3.3008 3.3008-1.5 3.3008-3.3008-1.5-3.3008-3.3008-3.3008zm0 4.6992c-0.69922 0-1.3008-0.60156-1.3008-1.3008 0-0.69922 0.60156-1.3008 1.3008-1.3008s1.3008 0.60156 1.3008 1.3008c0 0.70312-0.60156 1.3008-1.3008 1.3008z"></path>
                <path d="m43.316 94.305c1.5938 0.17578 1.3281 2.5625-0.26172 2.3867-1.5898-0.17578-1.3281-2.5625 0.26172-2.3867"></path>
                <path d="m37.602 88.801c0 1.5977-2.4023 1.5977-2.4023 0 0-1.6016 2.4023-1.6016 2.4023 0"></path>
                <path d="m24.398 92.102c0 1.5977-2.3984 1.5977-2.3984 0 0-1.6016 2.3984-1.6016 2.3984 0"></path>
                <path d="m25.07 75.891c1.3242 0.89844-0.027343 2.8828-1.3477 1.9844-1.3242-0.90234 0.027344-2.8867 1.3477-1.9844"></path>
                <path d="m11.301 76.801c-0.39844-0.39844-1-0.39844-1.3984 0l-0.30078 0.30078-0.30078-0.30078c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.30078 0.30078-0.30078 0.30078c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078l0.30078-0.30078 0.30078 0.30078c0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078 0.39844-0.39844 0.39844-1 0-1.3984l-0.30078-0.30078 0.30078-0.30078c0.39844-0.39844 0.39844-1 0-1.3984z"></path>
                <path d="m19.176 66.262c1.3203 0.90234-0.03125 2.8867-1.3516 1.9844-1.3242-0.90234 0.027343-2.8828 1.3516-1.9844"></path>
                <path d="m8.1758 65.156c1.3242 0.89844-0.027343 2.8828-1.3516 1.9844-1.3203-0.90234 0.03125-2.8867 1.3516-1.9844"></path>
                <path d="m21.898 54.898c0.60156 0 1-0.39844 1-1 0-0.60156-0.39844-1-1-1h-0.69922v-0.69922c0-0.60156-0.39844-1-1-1-0.60156 0-1 0.39844-1 1v0.69922h-0.69922c-0.60156 0-1 0.39844-1 1 0 0.60156 0.39844 1 1 1h0.69922v0.69922c0 0.60156 0.39844 1 1 1 0.60156 0 1-0.39844 1-1v-0.69922z"></path>
                <path d="m7.6016 48.699c-0.30078-0.5-0.89844-0.60156-1.3984-0.19922l-2.8008 2c-0.5 0.30078-0.60156 0.89844-0.19922 1.3984 0.19922 0.30078 0.5 0.39844 0.80078 0.39844 0.19922 0 0.39844-0.10156 0.60156-0.19922l2.8008-2c0.49219-0.29688 0.59375-0.99609 0.19531-1.3984z"></path>
                <path d="m12.801 31.801c-1.6016 0-3 1.3008-3 3s1.3008 3 3 3 3-1.3008 3-3-1.3008-3-3-3zm0 3.8984c-0.5 0-1-0.39844-1-1 0-0.60156 0.39844-1 1-1 0.60156 0 1 0.39844 1 1 0 0.60156-0.40234 1-1 1z"></path>
                <path d="m24.488 28.703c1.5938 0.17969 1.3281 2.5625-0.26172 2.3867-1.5898-0.17578-1.3281-2.5625 0.26172-2.3867"></path>
                <path d="m12.98 16.508c1.5938 0.17578 1.3281 2.5586-0.26172 2.3828s-1.3281-2.5625 0.26172-2.3828"></path>
                <path d="m28.102 18.199l-0.5-0.5 0.5-0.5c0.39844-0.39844 0.39844-1 0-1.3984-0.39844-0.39844-1-0.39844-1.3984 0l-0.5 0.5-0.5-0.5c-0.39844-0.39844-1-0.39844-1.3984 0-0.39844 0.39844-0.39844 1 0 1.3984l0.5 0.5-0.5 0.5c-0.39844 0.39844-0.39844 1 0 1.3984 0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078l0.5-0.5 0.5 0.5c0.19922 0.19922 0.5 0.30078 0.69922 0.30078 0.19922 0 0.5-0.10156 0.69922-0.30078 0.29688-0.39844 0.29688-0.99609 0-1.3984z"></path>
                <path d="m37.699 5.6992c0 1.6016-2.3984 1.6016-2.3984 0 0-1.5977 2.3984-1.5977 2.3984 0"></path>
                <path d="m46.801 10.301c0 1.5977-2.4023 1.5977-2.4023 0 0-1.6016 2.4023-1.6016 2.4023 0"></path>
            </g>
        </svg>
        <img src="assets/img/file.png" class="img-poliza">
    </div>
    <div class="message">Emitiendo póliza. . .</div>
</div>