import { Observable } from 'rxjs';
import { ServiceListDTO, TypeUseDTO, NationalityListDTO, VehicleRequestDTO, VehicleDTO } from '../../data/dto/VehicleDTO';
import { BrandListDTO, BrandListResponseDTO } from '../../data/dto/VehicleDTO';
import { ModelListDTO, TypeUserListDTO, GenderListDTO } from '../../data/dto/VehicleDTO';
import { SubrandListDTO, SubrandRequestDTO } from '../../data/dto/VehicleDTO';
import { DescriptionListDTO, DescriptionRequestDTO } from '../../data/dto/VehicleDTO';

export abstract class VehicleRepository {
    abstract getTypeUse(): Observable<TypeUseDTO>;
    abstract getBrandList(brand: BrandListDTO): Observable<BrandListResponseDTO>;
    abstract getModel(): Observable<ModelListDTO>;
    abstract getSubrand(subrand: SubrandRequestDTO): Observable<SubrandListDTO>;
    abstract getDescription(description: DescriptionRequestDTO): Observable<DescriptionListDTO>;
    abstract getGender(): Observable<GenderListDTO>;
    abstract getTypeUser(): Observable<TypeUserListDTO>;
    abstract getService(): Observable<ServiceListDTO[]>;
    abstract getNationality(): Observable<NationalityListDTO>;
    abstract createVehicle(vehicle: VehicleRequestDTO): Observable<VehicleDTO>;
}
