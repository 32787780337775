
<div class="col-md-12 p-2">
    <div class="bg-white p-4 rounded mt-1 shadow">
        <div class="row">
            <div class="col-md-2  d-flex align-items-center">
                <div class="d-flex flex-column justify-content-center">
                    <img src="assets/img/seguros/vector-logo.png" alt="logo" class="img-fluid img-vector">
                </div>
            </div>
            <div class="col-md-3 border-right d-flex align-items-center">
                <div class="d-flex flex-column justify-content-center post">
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="col-md-4 border-right">
                <div class="d-flex flex-column justify-content-center align-items-center post">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
            <div class="d-flex col-md-3">
                <div class="d-flex flex-column justify-content-center w-100 post">
                    <div class="avatar"></div>
                </div>
            </div>
        </div>
    </div>
</div>
