import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() {}

  login(token: string): any {
    localStorage.setItem('token', token);
    return token;
  }

}
