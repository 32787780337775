<section class="not-found">
  <div class="container">
      <div class="row">
          <div class="col-md-12">
              <div class="section-title">
                  <h2>{{localization.page404Title}}</h2>
                  <h3>{{localization.page404Text}}.</h3>
              </div>
          </div>
          <div class="col-md-12">
              <img src="assets/img/img-404.png" class="img-fluid centrar">
          </div>
      </div>
  </div>
</section>

